<script>
import Form from '../../../../../components/form';
import request from '../../../../../utils/request';

export default {
  extends: Form,
  components: {
  },
  data() {
    return {

    };
  },
  methods: {
    handleFieldChange(field, value) {
      if (field === 'customerOrgName') {
        this.setValue({
          customerOrgCode: value,
        });
      } else if (field === 'materialName') {
        this.setValue({
          materialCode: value,
        });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData();
      if (formData) {
        let url = '/mdm/mdmCustomerMaterialController/save';
        const params = { ...formData };

        if (this.formConfig.type === 'edit') {
          url = '/mdm/mdmCustomerMaterialController/update';
          params.id = this.formConfig.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
  mounted() {

  },
  async created() {
    await this.getFormRule('customer_meterial_form');
    const barCode = this.getRule('barCode');
    barCode.validate = [
      ...barCode.validate,
      {
        trigger: 'blur',
        pattern: '^[a-zA-Z0-9]*$',
        message: '仅能输入英文和数字的组合',
      },
    ];
    const customerOrgName = this.getRule('customerOrgName');
    customerOrgName.restfulParams = {
      enableStatus: '009',
    };
    customerOrgName.restful = '/mdm/baseTreeController/customerOrgTree';
    customerOrgName.headers = { functionCode: 'select-customer-org' };
    customerOrgName.value = null;
    customerOrgName.props = {
      options: [],
      size: 'small',
      placeholder: '请选择客户组织',
      disabled: false,
      loading: false,
      clearable: true, // 是否显示清空按钮
      props: {
        parent: 'parentCode', // 父级唯一标识
        value: 'code', // 唯一标识
        label: 'name', // 标签显示
        children: 'children', // 子级
      },
    };
    const materialName = this.getRule('materialName');
    materialName.restful = '/mdm/mdmMaterialController/materialSelectEnable';
    materialName.headers = { functionCode: 'select-goods' };
    materialName.optionsKey = { label: 'materialName', value: 'materialCode' };
    materialName.props = {
      ...materialName.props,
      filterable: true,
      remote: true,
      remoteParams: 'materialName',
    };

    // meterialCode.on.change = (value) => {
    //   console.log('value', value);
    // };
    this.getRule('customerOrgCode').props.disabled = true;
    this.getRule('materialCode').props.disabled = true;
    // this.setValue({ enableStatus: '009' });
    if (this.formConfig.type === 'edit') {
      request.get('/mdm/mdmCustomerMaterialController/query', { id: this.formConfig.id }).then((res) => {
        if (res.success) {
          this.setValue(res.result);
          customerOrgName.value = res.result && res.result.customerOrgCode;
          materialName.value = res.result && res.result.materialCode;
        }
      });
    }
  },
};
</script>
