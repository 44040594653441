var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "position-view" }, [
    _c("div", { staticClass: "position-view-basic" }, [
      _c("div", { staticClass: "position-view-item" }, [
        _vm._v("客户组织编码:" + _vm._s(_vm.formConfig.customerOrgCode)),
      ]),
      _c("div", { staticClass: "position-view-item" }, [
        _vm._v("客户组织名称:" + _vm._s(_vm.formConfig.customerOrgName)),
      ]),
      _c("div", { staticClass: "position-view-item" }, [
        _vm._v("物料编码:" + _vm._s(_vm.formConfig.materialCode)),
      ]),
      _c("div", { staticClass: "position-view-item" }, [
        _vm._v("物料名称:" + _vm._s(_vm.formConfig.materialName)),
      ]),
      _c("div", { staticClass: "position-view-item" }, [
        _vm._v("条形码:" + _vm._s(_vm.formConfig.barCode)),
      ]),
      _c("div", { staticClass: "position-view-item" }, [
        _vm._v("状态:" + _vm._s(_vm.formConfig.enableStatusName)),
      ]),
      _c("div", { staticClass: "position-view-item" }, [
        _vm._v("创建人:" + _vm._s(_vm.formConfig.createName)),
      ]),
      _c("div", { staticClass: "position-view-item" }, [
        _vm._v("创建时间:" + _vm._s(_vm.formConfig.createDateAll)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }