<script>
import TablePage from '../../../../components/table_page';
import Modal from '../../../../components/modal';
import Form from './form';
import materialView from './materialView/materialView.vue';

export default {
  name: 'proportion',
  extends: TablePage,
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  components: {
    Modal,
    Form,
    materialView,
  },
  methods: {
    // 按钮点击事件
    modalClick({ val, row }) {
      // console.log(2777777777, val);
      // console.log(2999999999, row);
      this.formConfig = {};
      this.formName = 'Form';
      if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '400px';
        this.formConfig = {
          type: val.code,
          id: null,
        };
        this.openModal();
      } else if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '400px';
        this.formConfig = {
          type: val.code,
          id: row.id,
        };
        this.openModal();
      } else if (val.code === 'view') {
        this.formName = 'materialView';
        this.modalConfig = {
          title: '基本信息',
          width: '40%',
        };
        this.formConfig = {
          ...row,
        };
        this.openDrawer();
      }
    },

  },
  created() {
    this.getConfigList('customer_meterial_table');
  },
};
</script>
