<template>
  <div class="position-view">
    <div class="position-view-basic">
      <div class = "position-view-item">客户组织编码:{{formConfig.customerOrgCode}}</div>
      <div class = "position-view-item">客户组织名称:{{formConfig.customerOrgName}}</div>
      <div class = "position-view-item">物料编码:{{formConfig.materialCode}}</div>
      <div class = "position-view-item">物料名称:{{formConfig.materialName}}</div>
      <div class = "position-view-item">条形码:{{formConfig.barCode}}</div>
      <div class = "position-view-item">状态:{{formConfig.enableStatusName}}</div>
      <div class = "position-view-item">创建人:{{formConfig.createName}}</div>
      <div class = "position-view-item">创建时间:{{formConfig.createDateAll}}</div>
    </div>

  </div>
</template>

<script>

export default {
  components: {

  },
  props: ['formConfig'],
  created() {
  },
  data() {
    return {

    };
  },
};
</script>

<style lang = "less">
.position-view{
  &-basic-title{
    margin-top: 0;
    margin-bottom:0.3rem;
  }
  &-basic{
    color:#72767b;
    display: flex;
    flex-wrap: wrap;
    .position-view-item{
      width: 33%;
      margin-bottom: 3rem;

    }
  }
}

</style>
